import React from 'react';
import PropTypes from 'prop-types';
import Cta from './Cta';
import PoiEditForm from 'components/PoiEditForm';

const RequestForm = ({
  isAuthenticated,
  regionChoices,
  timezoneChoices,
  mapboxToken,
  poiTypeChoices,
  onSubmit,
  onCancel,
}) => {
  if (!isAuthenticated) {
    return (
      <Cta
        isAuthenticated={false}
        isNewVenue
        isCancellable
        onCancel={onCancel}
      />
    )
  }

  return (
    <div>
      <PoiEditForm
        regionChoices={regionChoices}
        timezoneChoices={timezoneChoices}
        mapboxToken={mapboxToken}
        poiTypeChoices={poiTypeChoices}
        formType="basic"
        cancelButtonText="Cancel"
        submitButtonText="Submit"
        controlsClassName="mt-3 mx-3 border-top pt-3 d-flex justify-content-between"
        dummySubmit // TODO remove
        onSubmitComplete={onSubmit}
        onCancel={onCancel}
      />
    </div>
  );
};

RequestForm.propTypes = {
  isAuthenticated: PropTypes.bool,
  regionChoices: PropTypes.arrayOf(PropTypes.array),
  timezoneChoices: PropTypes.arrayOf(PropTypes.array),
  mapboxToken: PropTypes.string,
  poiTypeChoices: PropTypes.arrayOf(PropTypes.array),
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

export default RequestForm;
