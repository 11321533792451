import React, { useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import camelize from 'camelize';
import { components } from 'react-select';
import { useForm, FormProvider } from 'react-hook-form';
import { urls } from 'app-constants';
import { useHistory } from 'hooks';
import { LiveSearchSelectField } from 'components/formFields';
import PoiCard from 'components/PoiCard';
import Cta from './Cta';

const QUERY_MIN_CHARS = 3;

const SearchForm = ({
  poiData,
  isAuthenticated,
  isSubmitting,
  onRequestNew,
  onPoiChange,
  onSubmitClaim,
}) => {
  const formMethods = useForm();
  const val = formMethods.watch('venue');

  const getPoiData = (id, onSuccess = onPoiChange) => {
    fetch(`${urls.poiDetailBase}${id}/`)
      .then(response => {
        if (!response.ok) throw new Error(response.statusText);
        return response;
      })
      .then(response => response.json())
      .then(data => onSuccess(camelize(data)))
      .catch(err => {
        console.error(err);
        onPoiChange(null);
      });
  };

  useEffect(() => {
    if (val) {
      getPoiData(val);
    } else {
      onPoiChange(null);
    }
  }, [val]);

  const { location: { queryParams } } = useHistory();
  const { vid } = queryParams || {};
  useEffect(() => {
    if (vid) {
      formMethods.setValue('venue', vid)
    }
  }, [vid]);

  const handleRequestNew = useCallback(evt => {
    evt.preventDefault();
    onRequestNew();
  }, []);

  /* eslint-disable react/prop-types */
  // eslint-disable-next-line react/display-name
  const CustomMenuList = useMemo(() => props => {
    const { MenuList } = components;
    const showSubmitOpt = props.selectProps.inputValue.length >= QUERY_MIN_CHARS;

    return (
      <>
        <MenuList {...props}>{props.children}</MenuList>
        {showSubmitOpt && (
          <div className="claim-venue-form-menu-extra">
            <span>
              No match? <a href="#" onClick={handleRequestNew} onTouchStart={handleRequestNew}>Submit your venue</a> for inclusion in our database.
            </span>
          </div>
        )}
      </>
    );
  }, [handleRequestNew]);
  /* eslint-enable react/prop-types */

  return (
    <>
      <FormProvider {...formMethods}>
        <form>
          <LiveSearchSelectField
            name="venue"
            objectType="poi"
            minQueryLength={QUERY_MIN_CHARS}
            components={{ MenuList: CustomMenuList }}
            label="Enter Venue Name"
          />
        </form>
      </FormProvider>

      {!!poiData && (
        <>
          <div className="mt-4 mb-5">
            <PoiCard
              className="shadow"
              hideBookmarkButton
              targetBlank
              {...poiData}
            />
          </div>
          <Cta
            poiData={poiData}
            isAuthenticated={isAuthenticated}
            isClaimed={poiData.name.toLowerCase().includes('library')}
            isSubmitting={isSubmitting}
            onSubmit={onSubmitClaim}
          />
        </>
      )}
    </>
  );
};

SearchForm.propTypes = {
  poiData: PropTypes.object,
  isAuthenticated: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  onRequestNew: PropTypes.func,
  onPoiChange: PropTypes.func,
  onSubmitClaim: PropTypes.func,
};

export default SearchForm;
