import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import camelize from 'camelize';
import urlJoin from 'url-join';
import { useForm, FormProvider } from 'react-hook-form';
import { urls } from 'app-constants';
import { getCsrfToken, messages } from 'utils';
import {
  AddressField,
  CheckboxField,
  ImageField,
  RichTextField,
  SelectField,
  TextField,
  URLField,
  OpenTimesField,
  openTimesRowDefaults,
  hydrateOpenTimesValue,
  dehydrateOpenTimesValue,
} from 'components/formFields';
import DropdownButton from 'components/DropdownButton';
import Icon from 'components/Icon';
import LoadingOverlay from 'components/LoadingOverlay';
import PillButton from 'components/PillButton';

const PoiEditForm = ({
  poiId,
  regionChoices,
  timezoneChoices,
  mapboxToken,
  poiTypeChoices,
  formType = 'standard',
  cancelButtonText,
  submitButtonText,
  controlsClassName,
  // TODO - tmp
  dummySubmit = false,
  onSubmitComplete,
  onCancel,
}) => {
  const [isFetching, setIsFetching] = useState(!!poiId);
  const [apiValidationErrors, setApiValidationErrors] = useState({});
  const fieldValidator = fieldName => () => !apiValidationErrors[fieldName] || apiValidationErrors[fieldName].join(' ');

  const isBasicForm = formType === 'basic';
  const isAdminForm = formType === 'admin';

  const defaultValues = useMemo(() => ({
    name: '',
    website: '',
    phone: '',
    email: '',
    description: '',
    lon: '',
    lat: '',
    street: '',
    city: '',
    state: '',
    postal_code: '',
    country: 'US',
    time_zone: 'America/New_York',
    region: (regionChoices.find(c => c[2] === true) || regionChoices[0])[0],
    alternate_times: '',
    show_alternate_times: false,
    show_date_time_picker: true,
    open_times: [{ ...openTimesRowDefaults }],
    image: '',
    image_crop_data: null,
    osm_id: '',
    osm_type: '',
    poi_type: '',
    wikipedia_url: '',
    wikidata_id: '',
    facebook_url: '',
    instagram_url: '',
    twitter_url: '',
    notes: '',
    hide_from_listing: false,
    recertify: false,
  }), [regionChoices]);

  const formMethods = useForm({
    mode: 'onTouched',
    defaultValues,
  });
  const { formState: { errors: formErrors, isValid: formIsValid } } = formMethods;
  const formHasErrors = Object.keys(formErrors).length > 0;

  const clearForm = () => {
    formMethods.reset(defaultValues);
    setTimeout(() => formMethods.clearErrors(), 50);
  };

  const debugFillForm = useCallback(() => {
    const debugValues = {
      name: `Debug Venue - ${(new Date()).toLocaleString()}`,
      website: 'http://example.com',
      phone: '(555) 867-5309',
      email: 'hello@example.com',
      description: 'Dolor consequat deserunt officia fugiat laboris veniam ut non irure voluptate nostrud ut enim.',
      lon: -73.2542625,
      lat: 42.4489702,
      street: '',
      city: 'Pittsfield',
      state: 'MA',
      postal_code: '01201',
      country: 'US',
      time_zone: 'America/New_York',
      region: (regionChoices.find(c => c[2] === true) || regionChoices[0])[0],
      alternate_times: '',
      show_alternate_times: false,
      show_date_time_picker: true,
      open_times: [
        { days: [1, 2, 3, 4, 5], startTime: '9:00 AM', endTime: '12:00 PM' },
        { days: [5, 6], startTime: '5:00 PM', endTime: '10:00 PM' },
      ],
      image: '',
      image_crop_data: null,
      osm_id: '',
      osm_type: '',
      poi_type: '',
      wikipedia_url: '',
      wikidata_id: '',
      facebook_url: '',
      instagram_url: '',
      twitter_url: '',
      notes: '',
      hide_from_listing: false,
      recertify: false,
    };

    formMethods.reset(debugValues);
    setTimeout(() => formMethods.clearErrors(), 50);
  }, []);

  const handleKeyDown = useCallback(evt => {
    if (evt.key === '1' && evt.metaKey && evt.shiftKey) {
      evt.preventDefault();
      debugFillForm();
    }
  }, [debugFillForm]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [handleKeyDown]);

  const [poiData, setPoiData] = useState(null);

  const hydratePoiData = data => {
    /* eslint-disable camelcase */
    let { open_times, ...rest } = data;
    // rest = Object.entries(rest).reduce((result, [key, val]) => {
    //   result[key] = val === null ? '' : val;
    //   return result;
    // }, {});
    open_times = hydrateOpenTimesValue(open_times);
    return { ...rest, open_times };
    /* eslint-enable */
  };

  const fetchPoiData = () => {
    if (!poiId) return null;
    const url = urlJoin(urls.apiEditVenueBase, `${poiId}/`);
    setIsFetching(true);

    fetch(url)
      .then(response => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response;
      })
      .then(response => response.json())
      .then(data => {
        setPoiData(hydratePoiData(data));
        setIsFetching(false);
      })
      .catch(err => {
        console.error(err);
        setIsFetching(false);
      });
  };

  useEffect(() => {
    clearForm();
    fetchPoiData();
  }, [poiId]);

  useEffect(() => {
    if (poiData) {
      formMethods.reset(poiData);
      setTimeout(formMethods.trigger, 100);
    }
  }, [poiData]);

  const savePoiData = payload => {
    const url = poiId ? urlJoin(urls.apiEditVenueBase, `${poiId}/`) : urls.apiAddVenue;
    const method = poiId ? 'PUT' : 'POST';

    setApiValidationErrors({});
    setIsFetching(true);

    fetch(url, {
      credentials: 'include',
      method,
      headers: {
        'X-CSRFToken': getCsrfToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then(response => {
        setIsFetching(false);

        if (response.status === 400) {
          // validation errors
          response.json().then(setApiValidationErrors);
        } else if (!response.ok) {
          throw new Error(response.statusText);
        } else {
          response.json().then(data => {
            const { absoluteUrl } = camelize(data);
            const msg = `Successfully ${poiId ? 'updated' : 'created'} venue "${data.name}".`;
            messages.add(messages.success, msg, () => window.location.href = absoluteUrl);
          });
        }
        return response;
      })
      .catch(err => {
        console.error(err);
        setIsFetching(false);
      });
  };

  useEffect(() => {
    if (Object.keys(apiValidationErrors).length > 0) {
      formMethods.trigger();
    }

    const subscription = formMethods.watch((value, { name, type }) => {
      if (apiValidationErrors[name]) {
        setApiValidationErrors(oldState => {
          const { [name]: _, ...rest } = oldState;
          return rest;
        });
        setTimeout(() => formMethods.trigger(name), 100);
      }
    });

    return () => subscription.unsubscribe();
  }, [JSON.stringify(apiValidationErrors)]);

  const onSubmit = data => {
    const apiData = Object.entries(data).reduce((result, [key, value]) => {
      switch (key) {
        case 'open_times':
          result[key] = dehydrateOpenTimesValue(value);
          break;
        default:
          result[key] = value;
      }
      return result;
    }, {});

    if (isBasicForm) {
      apiData.show_alternate_times = !!apiData.alternate_times;
      apiData.show_date_time_picker = apiData.open_times.length > 0;
    }

    if (dummySubmit) {
      setIsFetching(true);
      setTimeout(() => {
        setIsFetching(false);
        onSubmitComplete && onSubmitComplete();
      }, 2000);
    } else {
      savePoiData(apiData);
      onSubmitComplete && onSubmitComplete();
    }

  };

  const handleCancelClick = () => {
    onCancel ? onCancel() : window.location.href = (poiData.absolute_url || urls.events);
  };

  const [initialDataLoaded, setInitialDataLoaded] = useState(false);
  useEffect(() => {
    if (!poiId || !!poiData) {
      setTimeout(() => setInitialDataLoaded(true), 200);
    }
  }, [poiId, poiData]);

  return (
    <div className="edit-form-container">
      {!!poiId && <LoadingOverlay show={isFetching} align="top" />}
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <div className="container-fluid">
            <h2 className="fw-bold mb-4">Venue Basic Information</h2>

            <div className="row mb-3">
              <TextField
                name="name"
                label="Name"
                required
                maxLength={1024}
                validate={fieldValidator('name')}
              />
            </div>

            <div className="row mb-3">
              <URLField
                name="website"
                label="Website"
                maxLength={1024}
                validate={fieldValidator('website')}
              />
            </div>

            <div className="row mb-4">
              <div className="col-6">
                <TextField
                  name="phone"
                  label="Phone"
                  maxLength={128}
                  validate={fieldValidator('phone')}
                />
              </div>

              <div className="col-6">
                <TextField
                  name="email"
                  label="Email"
                  maxLength={512}
                  validate={fieldValidator('email')}
                />
              </div>
            </div>

            <hr className="my-5" />

            <h2 className="fw-bold mb-4">Venue Details</h2>

            {initialDataLoaded && (
              <div className="row mb-4">
                <div className="col">
                  <ImageField
                    name="image"
                    label=""
                    cropDataName="image_crop_data"
                    initialImage={poiData && poiData.image_url}
                    initialCropData={poiData && poiData.image_crop_data}
                    validate={fieldValidator('image')}
                  />
                </div>
              </div>
            )}

            <div className="row mb-3">
              <div className="col">
                <RichTextField
                  name="description"
                  label="Description"
                  maxLength={2000}
                  validate={fieldValidator('description')}
                />
              </div>
            </div>

            <div className="row mb-4">
              <h5 className="mb-0">Hours of Operation</h5>
            </div>

            {!isBasicForm && (
              <div className="row mb-1">
                <CheckboxField
                  name="show_date_time_picker"
                  label="Show date/times"
                />
              </div>
            )}

            <OpenTimesField name="open_times" />

            <div className="mt-4">
              {!isBasicForm && (
                <div className="row mb-2">
                  <CheckboxField
                    name="show_alternate_times"
                    label="Show alternate hours"
                  />
                </div>
              )}

              <div className="row mb-4">
                <div className="col">
                  <TextField
                    name="alternate_times"
                    label="Alternate Hours / Notes"
                    multiline
                    maxLength={500}
                  />
                </div>
              </div>
            </div>

            <hr className="my-5" />

            <h2 className="fw-bold mb-4">Location</h2>

            <AddressField
              className="mb-5"
              mapboxToken={mapboxToken}
              initialDataLoaded={initialDataLoaded}
              initialCoords={poiData ? [poiData.lon, poiData.lat] : undefined}
              verticalLayout
            />

            <div className="row mb-4">
              <SelectField
                name="time_zone"
                label="Timezone"
                required
                choices={timezoneChoices}
                validate={fieldValidator('time_zone')}
              />
            </div>

            {!isBasicForm && (
              <div className="row mb-4">
                <SelectField
                  name="region"
                  label="Region"
                  required
                  choices={regionChoices}
                  validate={fieldValidator('region')}
                />
              </div>
            )}

            <hr className="my-5" />

            <h2 className="fw-bold mb-4">Metadata</h2>

            {!isBasicForm && (
              <div className="row mb-4">
                <SelectField
                  name="poi_type"
                  label="POI Type"
                  choices={poiTypeChoices}
                  validate={fieldValidator('poi_type')}
                />
              </div>
            )}

            {isAdminForm && (
              <>
                <div className="row mb-4">
                  <SelectField
                    name="osm_type"
                    label="OSM Type"
                    choices={[
                      ['node', 'Node'],
                      ['way', 'Way'],
                      ['relation', 'Relation'],
                    ]}
                    validate={fieldValidator('osm_type')}
                  />
                </div>

                <div className="row mb-2">
                  <TextField
                    name="osm_id"
                    label="OSM ID"
                    maxLength={16}
                    validate={fieldValidator('osm_id')}
                  />
                </div>

                <div className="row mb-2">
                  <TextField
                    name="wikidata_id"
                    label="Wikidata ID"
                    maxLength={512}
                    validate={fieldValidator('wikidata_id')}
                  />
                </div>
              </>
            )}

            <div className="row mb-2">
              <URLField
                name="wikipedia_url"
                label="Wikipedia URL"
                maxLength={512}
                validate={fieldValidator('wikipedia_url')}
              />
            </div>

            <div className="row mb-2">
              <URLField
                name="facebook_url"
                label="Facebook URL"
                maxLength={512}
                validate={fieldValidator('facebook_url')}
              />
            </div>

            <div className="row mb-2">
              <URLField
                name="instagram_url"
                label="Instagram URL"
                maxLength={512}
                validate={fieldValidator('instagram_url')}
              />
            </div>

            <div className="row">
              <URLField
                name="twitter_url"
                label="Twitter (X) URL"
                maxLength={512}
                validate={fieldValidator('twitter_url')}
              />
            </div>

            {isAdminForm && (
              <>
                <hr className="my-5" />

                <h2 className="fw-bold mb-4">Editor Tools</h2>

                <div className="row mb-2">
                  <div className="col">
                    <TextField
                      name="notes"
                      label="Notes"
                      multiline
                      maxLength={500}
                    />
                  </div>
                </div>

                <div className="row mb-2">
                  <CheckboxField
                    name="hide_from_listing"
                    label="Hide from listings"
                  />
                </div>

                <div className="row mb-2">
                  <CheckboxField
                    name="recertify"
                    label="Recertify based on this edit"
                  />
                </div>
              </>
            )}
          </div>

          <div className={controlsClassName || 'edit-form-controls'}>
            <PillButton
              text={cancelButtonText || (poiId ? 'Cancel' : 'Discard')}
              className="text-danger clear"
              prependIcon={['far', 'times']}
              onClick={handleCancelClick} // TODO - redirect to dashboard
            />
            <div className="d-flex align-items-center">
              {formHasErrors && <div className="text-sm text-danger me-2">Please correct form errors before submitting.</div>}

              {!!poiId && isAdminForm && (
                <DropdownButton
                  buttonClassName="subtle-focus circle me-2"
                  menuAnchor="bottomRight"
                  prependIcon={<Icon i={['far', 'ellipsis-v']} />}
                  appendIcon={null}
                >
                  <div className="link-list py-3">
                    <a
                      href={`${urls.deleteVenueBase}${poiId}/`}
                      className="link-list-item px-3"
                    >
                      <span className="text-danger">Delete</span>
                    </a>
                  </div>
                </DropdownButton>
              )}

              <input
                type="submit"
                value={submitButtonText || (poiId ? 'Save' : 'Publish')}
                className="btn-z primary"
                disabled={isFetching || !formIsValid}
              />
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

PoiEditForm.propTypes = {
  poiId: PropTypes.number,
  regionChoices: PropTypes.arrayOf(PropTypes.array),
  timezoneChoices: PropTypes.arrayOf(PropTypes.array),
  mapboxToken: PropTypes.string,
  poiTypeChoices: PropTypes.arrayOf(PropTypes.array),
  formType: PropTypes.oneOf(['basic', 'standard', 'admin']),
  controlsClassName: PropTypes.string,
  cancelButtonText: PropTypes.string,
  submitButtonText: PropTypes.string,
  dummySubmit: PropTypes.bool,
  onSubmitComplete: PropTypes.func,
  onCancel: PropTypes.func,
};

export default PoiEditForm;
